.spatula {
    display: flex;

    .spatula-logo {
        .Image('/spatulaProject/synthesis.png', 222px, 381px);
    }

    .spatula-body {
        display: flex;
        align-items: baseline;

        .collection-link {
            flex-shrink: 0;
            text-decoration: none;
            color: #5593ce;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            padding: 0 15px;
        }
    }
}
