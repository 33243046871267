@OnlineImageRoot: 'https://d8lc6s7bpjqw2.cloudfront.net/grafist/images';
@LocalImageRoot: './images';

.Image(@url, @width, @height, @cursor: auto, @online: true) {
    & when (@online = true) {
        background-image: url('@{OnlineImageRoot}@{url}');
    }
    & when not (@online = true) {
        background-image: url('@{LocalImageRoot}@{url}');
    }
    background-repeat: no-repeat;
    width: @width;
    height: @height;
    cursor: @cursor;
    flex-shrink: 0;
}

.Link(@color: #5e5f61, @hoverColor:#f5863f) {
    color: @color;
    text-decoration: none;

    &:hover {
        color: @hoverColor;
    }
}
