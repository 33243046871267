.contact {
    display: flex;

    .contact-logo {
        .Image('/contact/synthesis.png', 228px, 373px);
    }

    .contact-details {
        .details {
            .detail {
                display: flex;
                padding: 1px 0;

                .caption {
                    width: 90px;
                }

                .link {
                    .Link;
                }
            }
        }

        .icons {
            display: flex;
            margin: 25px 0 0 90px;

            :not(:first-child) {
                margin-left: 10px;
            }

            .link-icon {
                cursor: pointer;
                font-size: 32px;
                margin-right: 10px;

                &:hover {
                    color: #363639 !important;
                }

                &.facebook {
                    color: #3798d4;
                }
                &.twitter {
                    color: #00bcf2;
                }
                &.linkedin {
                    color: #2671b9;
                }
                &.pinterest {
                    color: #ed1b31;
                }
                &.web {
                    .Image('/contact/icons/web_active.png', 32px, 31px, pointer, false);

                    &:hover {
                        .Image('/contact/icons/web_over.png', 32px, 31px, pointer, false);
                    }
                }
                &.issuu {
                    .Image('/contact/icons/issuu_active.png', 32px, 31px, pointer, false);

                    &:hover {
                        .Image('/contact/icons/issuu_over.png', 32px, 31px, pointer, false);
                    }
                }
            }
        }
    }
}
