.MenuItemImage(@which, @width, @height) {
    .Image('/general/menu/@{which}_down.png', @width, @height, pointer, false);

    &:hover {
        .Image('/general/menu/@{which}_over.png', @width, @height, pointer, false);
    }

    &.selected {
        .Image('/general/menu/@{which}_active.png', @width, @height, pointer, false);
    }
}

.header {
    display: flex;
    justify-content: center;
    height: 120px;
    width: 865px;
    color: #bcbdc0;
    margin-left: auto;
    margin-right: auto;

    .logo {
        .Image('/portfolio/logo.png', 208px, 100px);

        &.portfolio,
        &.downloads {
            .Image('/portfolio/logo.png', 208px, 100px);
        }
        &.persona,
        &.spatula-project,
        &.contact {
            .Image('/contact/logo_black.png', 222px, 99px);
        }
    }

    .header-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 646px;

        .thick-line {
            margin-left: -46px;
            border: 5px solid black;
        }

        .menu-item-wrapper {
            display: flex;
            flex-direction: column;
        }

        .header-items {
            display: flex;
            color: #808284;
            font-weight: normal;
            font-size: 14px;
            margin-top: 8px;
            margin-left: -30px;

            .header-item {
                display: flex;
                align-items: center;
                margin: 0 3px;

                &:first-child {
                    margin-left: 0;
                }

                .header-caption {
                    margin-left: 2px;
                }

                input[type='radio'] {
                    margin-top: -2px;
                }
            }
        }

        .main-menu {
            margin-left: 35px;
            margin-right: 10px;
            display: flex;
            justify-content: space-between;

            .menu-items {
                display: flex;
                justify-content: space-between;

                .menu-item {
                    margin: 0 5px;
                    position: relative;

                    .selected-stripe {
                        position: absolute;
                        height: 3px;
                        background-color: black;
                        width: 100%;
                        top: 61px;
                    }
                }

                .portfolio {
                    .MenuItemImage('portfolio', 63px, 51px);
                }
                .persona {
                    .MenuItemImage('persona', 76px, 51px);
                }
                .spatula-project {
                    .MenuItemImage('spatulas', 72px, 51px);
                }
                .downloads {
                    .MenuItemImage('downloads', 72px, 51px);
                }
                .contact {
                    .MenuItemImage('contact', 55px, 51px);
                }
            }

            .languages,
            .search-menu,
            .right {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }

            .right {
                margin-left: 55px;
            }

            .language {
                font-size: 20px;
                margin: 0 2px;
                font-weight: bold;
                cursor: pointer;

                &.selected,
                &:hover {
                    color: #ffcb04;
                }
            }

            .language-link {
                text-decoration: none;
                color: #bcbdc0;
            }

            .search {
                margin-left: 5px;
                cursor: pointer;
                font-size: 17px;
                transform: scale(-1, 1);

                &:hover {
                    color: #818285;
                }
            }

            .search-input {
                width: 130px;
            }
        }
    }
}
