.main-body {
    flex: 1;
    overflow: auto;

    .main-body-title {
        font-weight: bold;
        color: #8d8f92;
        font-size: 17px;
        font-family: Tahoma;
    }

    .main-body-details {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        color: #5e5f61;
        font-size: 17px;

        .details {
            margin-top: 25px;

            p {
                text-indent: 15px;
                margin: 0;
            }
        }
    }

    &.portfolio,
    &.spatula-collection {
        background-color: #e5e6e7;
        padding: 5px;
    }

    &.persona,
    &.spatula,
    &.contact {
        width: 870px;
        margin-left: auto;
        margin-right: auto;
    }

    .grid-item {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 7px;
        border: 2px solid #cfd1d2;
        box-shadow: 2px 2px 4px #d9dadb;
        cursor: pointer;
        user-select: none;

        &:hover {
            border-color: #8c8e90;
        }

        .grid-item-image {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .grid-item-description {
            padding: 8px 10px 0 10px;
            color: #77787b;
            font-size: 15px;
        }

        .grid-item-details {
            display: flex;
            color: #bcbec0;
            font-size: 14px;
            padding: 7px 10px;
        }

        hr {
            height: 2px;
            width: 100%;
            color: #f3f3f4;
            background-color: #f3f3f4;
            border-width: 0;
        }
    }
}
