.persona {
    display: flex;

    .persona-logo {
        .Image('/persona/synthesis.png', 226px, 381px);
    }

    .cv {
        display: flex;
        align-items: center;
        margin-top: 25px;

        a {
            border: none;
            background-color: white;
            padding: 0;
            font-size: inherit;
            color: inherit;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                color: #f5863f;
            }
        }

        .pdf-icon {
            font-size: 25px;
            margin-right: 10px;
            color: #ee515f;
        }
    }
}
