@import 'header';
@import 'body';
@import 'contact';
@import 'persona';
@import 'portfolio';
@import 'spatula';
@import 'mixins';
@import 'spatulaCollection';
@import '~react-virtualized/styles.css';
@import '~react-image-lightbox/style.css';

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.index {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;

    .index-synthesis {
        z-index: -1;

        .Image('/index/synthesis.png', 604px, 444px);
    }

    .links {
        margin-left: -75px;
        font-size: 25px;
        margin-top: 270px;

        .link {
            margin-bottom: 10px;
            cursor: pointer;

            a {
                text-decoration: none;
                color: #afaeae;

                &:hover {
                    color: black;
                }
            }
        }
    }
}

.footer {
    margin-bottom: 5px;
    text-align: center;
    font-size: 13px;
    height: 30px;

    a {
        color: #ea4412;

        &:hover {
            color: black;
        }
    }

    hr {
        border: 1px solid #919191;
    }
}
