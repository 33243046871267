.portfolio {
    .ReactVirtualized__Masonry {
        outline: none;
    }

    .grid-item .grid-item-details .category-image {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
}

.lightbox-wrapper {
    img {
        pointer-events: none;
    }

    .zoom-image-caption {
        .zoom-image-description {
            font-size: 13px;
            color: #bbbbbb;
        }

        .zoom-image-count {
            font-size: 12px;
            color: #999999;
        }
    }

    .ril-zoom-in,
    .ril-zoom-out,
    .ril-prev-button,
    .ril-next-button {
        outline: none;
    }
}
