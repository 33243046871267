.collection {
    display: flex;
    flex-wrap: wrap;

    .grid-item {
        margin: 5px;

        .grid-item-image {
            width: 218px;
            height: 218px;
        }

        .grid-item-details {
            color: #77787b;
        }

        .dimensions-image {
            margin-right: 7px;

            .Image('/general/dimensions.jpg', 15px, 15px);
        }
    }
}

.lightbox-wrapper .zoom-image-caption {
    .zoom-image-description.spatula-collection {
        font-size: 14px;
        font-weight: bold;
    }

    .zoom-image-dimensions {
        font-weight: normal;
        color: #bbbbbb;
        font-size: 14px;
    }
}
